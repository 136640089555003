/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-09-29",
    versionChannel: "nightly",
    buildDate: "2023-09-29T00:21:45.562Z",
    commitHash: "ec7a8ac6453cbc48d12aaffc125dd4de79340f5b",
};
